
.root {
  position: relative;
  width: 1275px;
  height: 1650px;
  align-items: flex-start;
  background-color: #fff;
  overflow: hidden;
}
.rectangle20 {
  transform-origin: top left;
  position: absolute;
  left: 1325px;
  top: -49px;
  width: 1374px;
  height: 848px;
  /* background-color: #d9d9d9;  */
  background-image: url('../../assets/images/flyer_main.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto, auto, cover;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.rectangle15 {
  position: absolute;
  left: 890px;
  top: 1169px;
  width: 385px;
  height: 441px;
  background-color: #ebf8ff;
}
.rectangle5 {
  position: absolute;
  left: 950px;
  top: 1211px;
  width: 265px;
  height: 341px;
  outline: solid 1px #e18840;
  border-radius: 8px;
  background-color: #e18840;
}
.qrCodeCanamexcurions1 {
  position: absolute;
  left: 950px;
  top: 1211px;
  width: 265px;
  height: 265px;
  align-items: flex-start;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.frame {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.icon {
  width: 100%;
  height: 100%;
}
.sCANME {
  color: #fff;
  font-size: 44px;
  font-weight: 800;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 1005px;
  top: 1486px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.etPhone {
  position: absolute;
  left: 966px;
  top: 1492px;
  width: 24px;
  height: 40px;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.puntaCana {
  color: #fff;
  font-size: 220px;
  font-family: 'Bebas Neue', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 50%;
  top: 209px;
  width: min-content;
  height: min-content;
  text-shadow: 16.721311569213867px 16.721311569213867px 65.49180603027344px #00000080;
  white-space: nowrap;
  flex-direction: column;
  transform: translateX(-50%);
}
.excursions {
  color: #fff;
  font-size: 220px;
  font-family: 'Bebas Neue', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 50%;
  top: 380.3934px;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  text-shadow: 16.721311569213867px 16.721311569213867px 65.49180603027344px #00000080;
  white-space: nowrap;
  flex-direction: column;
  transform: translateX(-50%);
}
.rectangle13 {
  position: absolute;
  left: 0;
  top: 1610px;
  width: 1275px;
  height: 40px;
  background-color: #023c68;
}
.rectangle16 {
  position: absolute;
  left: 0;
  top: 837px;
  width: 1275px;
  height: 354px;
  background-color: #f7f7f7;
}
.rectangle17 {
  position: absolute;
  left: 60px;
  top: 1169px;
  width: 372px;
  height: 49px;
  background-color: #e18840;
}
.aBOUTUS {
  color: #fff;
  font-size: 30px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 92px;
  top: 1176px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.wHYCHOOSEUS {
  color: #e18840;
  font-size: 30px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 60px;
  top: 1351px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.poremIpsumDolorSitAmetConsecte {
  color: #002c44;
  font-size: 16px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 60px;
  top: 1235px;
  width: 770px;
  height: min-content;
  flex-direction: column;
}
.frame1 {
  position: absolute;
  left: 60px;
  top: 737px;
  width: min-content;
  height: min-content;
  align-items: flex-start;
  gap: 20px;
}
.rectangle19 {
  width: 372px;
  height: 402px;
  outline: solid 20px #fff;
  outline-offset: -20px;
  background-color: #d9d9d9;
  background-image: url('../../assets/images/flyer_1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rectangle132 {
  width: 372px;
  height: 402px;
  outline: solid 20px #fff;
  outline-offset: -20px;
  background-color: #d9d9d9;
  background-image: url('../../assets/images/flyer_2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rectangle18 {
  width: 372px;
  height: 402px;
  outline: solid 20px #fff;
  outline-offset: -20px;
  background-color: #d9d9d9;
  background-image: url('../../assets/images/flyer_3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.yOURSAFETYOURPRIORITYPoremIpsu {
  position: absolute;
  left: 119px;
  top: 1407px;
  width: 411px;
  height: min-content;
  flex-direction: column;
}
.textBlock {
  color: #002c44;
  font-size: 14px;
  font-weight: 500;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.textBlock2 {
  margin-top: 0;
}
.labelWrapper {
  font-size: 14px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label {
  color: #002c44;
  font-size: 14px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.yOURBOOKINGISGUARANTEEDPoremIp {
  position: absolute;
  left: 119px;
  top: 1472px;
  width: 411px;
  height: min-content;
  flex-direction: column;
}
.textBlock3 {
  color: #002c44;
  font-size: 14px;
  font-weight: 500;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.textBlock4 {
  margin-top: 0;
}
.labelWrapper2 {
  font-size: 14px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label2 {
  color: #002c44;
  font-size: 14px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.tRUSTEDBYCANADIANSAMERICANSPor {
  position: absolute;
  left: 119px;
  top: 1540px;
  width: 411px;
  height: min-content;
  flex-direction: column;
}
.textBlock5 {
  color: #002c44;
  font-size: 14px;
  font-weight: 500;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.textBlock6 {
  margin-top: 0;
}
.labelWrapper3 {
  font-size: 14px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label3 {
  color: #002c44;
  font-size: 14px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
}
.canadaMapleLeafSvgrepoCom2 {
  position: absolute;
  left: 61px;
  top: 1536px;
  width: 40px;
  height: 40px;
}

.canadaMapleLeafSvgrepoCom3{
  position: absolute;
  left: 61px;
  top: 1473px;
  width: 40px;
  height: 40px;
}
.icon3 {
  width: 100%;
  height: 100%;
}
.safetySvgrepoCom1 {
  position: absolute;
  left: 61px;
  top: 1403px;
  width: 40px;
  height: 40px;
}
.icon4 {
  width: 100%;
  height: 100%;
}
.wwwCanamexcursionsCom {
  color: #000;
  font-size: 20px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 954px;
  top: 1569px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame2 {
  position: absolute;
  left: 50%;
  top: 622.7377px;
  width: min-content;
  height: min-content;
  padding: 8px 160px;
  border-radius: 60px;
  background-color: #fff;
  overflow: hidden;
  transform: translateX(-50%);
}
.filledWithThrillAdventures {
  color: #e18840;
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.cANAMLOGOWhitee1 {
  position: absolute;
  left: calc(50% - -0.5px);
  top: 60px;
  width: 222px;
  height: 105px;
  background-image: url('../../assets/images/flyer_logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 12px 12px 50px 0px #00000040;
  transform: translateX(-50%);
}
.dOMINICANREPUBLIC {
  color: #fff;
  font-size: 50.1639px;
  font-family: Raleway, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  position: absolute;
  left: 50%;
  top: 200px;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  transform: translateX(-50%);
}
.rectangle21 {
  position: absolute;
  left: 58px;
  top: 1468px;
  width: 46px;
  height: 41px;
  /* background-color: #002c44; */
  /* -webkit-mask-image: url('../../assets/icons/maskGroup.svg');
  mask-image: url('../../assets/icons/maskGroup.svg'); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: 3px 0px;
  mask-position: 3px 0px;
  -webkit-mask-size: 40px 40px;
  mask-size: 40px 40px;
}
